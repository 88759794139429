<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="" style="width: 200px; margin-right: 20px">
        <el-input
          type="text"
          placeholder="输入单据编号"
          v-model="searcForm.no"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="" style="width: 200px; margin-right: 20px">
        <el-input
          type="text"
          placeholder="输入客户名称/编号/联系电话"
          v-model="searcForm.param"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="业务日期" style="width: 440px">
        <el-date-picker
          v-model="pickerDate"
          type="daterange"
          range-separator="至"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          end-placeholder="结束日期"
          :clearable="true"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="单据状态:" style="width: 280px">
        <el-select v-model="searcForm.status" placeholder="" clearable>
          <el-option label="已完成" :value="0"> </el-option>
          <el-option label="草稿" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" @click="searchForm">查询</el-button>
          <el-button type="default" @click="reset">清空</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="FSB" style="margin-bottom: 15px">
      <div></div>
      <div>
        <!-- <el-button type="primary" @click="addSaleList">新增</el-button>
        <el-button type="primary" @click="handleDownload" plain>导出</el-button> -->
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="supplierCode" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="watchDetail(scope.row.id)"
            >查看</el-button
          >
          <el-button
            :disabled="scope.row.status == '0'"
            type="text"
            @click="edit(scope.row.id)"
            >修改</el-button
          >
          <el-button type="text" @click="delList(scope.row.id)">删除</el-button>
          <el-button type="text" @click="exportData(scope.row.id)"
            >打印</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="entryDate"
        label="业务日期"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="no"
        label="单据编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="wmsName"
        label="默认仓库"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="客户" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}({{ scope.row.code }})
        </template>
      </el-table-column>
      <el-table-column prop="handlePrice" label="应收金额" align="right">
        <template slot-scope="scope">
          {{
            scope.row.handlePrice
              ? (scope.row.handlePrice / 100).toFixed(2)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="prepaidPrice" label="已收金额" align="right">
        <template slot-scope="scope">
          {{
            scope.row.prepaidPrice
              ? (scope.row.prepaidPrice / 100).toFixed(2)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="payStatus" label="单据状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == '0'" style="color: green">
            已完成
          </span>
          <span v-else style="color: orange">草稿</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createUserName"
        label="制单人"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="changePageSize"
      @current-change="changeCurrentPage"
      :current-page="searcForm.pageNum"
      :page-size="searcForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { datatimestatus } from "@/until/auth";
import { getWebOrder, delWebOrder, getSaleDetails } from "@/api/sale";
import { openSalePrintWindow } from "@/until/exportDetail";

export default {
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 20,
        no: "",
        param: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      total: 0,
      tableData: [],
      pickerDate: [],
      supplierData: [],
      pickerOptions: null,
    };
  },
  created() {
    this.getDate();
    this.getWebOrder();

    this.datatimestatus();
  },
  watch: {
    pickerDate(val) {
      if (!val) {
        this.searcForm.startDate = "";
        this.searcForm.endDate = "";
      } else {
        this.searcForm.startDate = val[0];
        this.searcForm.endDate = val[1];
      }
    },
  },
  methods: {
    watchDetail(id) {
      this.$router.push({
        path: "/sale/retailOutletList/watchSaleOut",
        query: { id },
      });
    },
    async delList(id) {
      let res = await delWebOrder(id);
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getWebOrder();
      } else {
        this.$message({
          type: "warning",
          message: res.msg,
        });
      }
    },
    addSaleList() {
      this.$router.push({
        path: "/sale/retailOutletList/addSaleOut",
      });
    },
    edit(id) {
      this.$router.push({
        path: "/sale/retailOutletList/addSaleOut",
        query: { id },
      });
    },
    exportData(id) {
      getSaleDetails({ id }).then((res) => {
        if (res.code === 1) {
          openSalePrintWindow(res.data, "销售单");
        }
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getWebOrder();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getWebOrder();
    },
    async getWebOrder() {
      let params = this.searcForm;
      let res = await getWebOrder(params);
      if (res.code == 1) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    searchForm() {
      this.searcForm.pageNum = 1;
      this.getWebOrder();
    },
    // 导出单个表头
    handleDownload() {
      import("@/until/Export2Excel").then((excel) => {
        const header = [
          "单据日期",
          "单据号",
          "客户",
          "应收金额",
          "已收金额",
          "收款状态",
          "制单人",
        ]; // 最后一行的表头数据
        const filterVal = [
          "entryDate",
          "no",
          "name",
          "handlePrice",
          "prepaidPrice",
          "payStatus",
          "createUserName",
        ];
        // 这里要注意 header  和 filterVal 的数组长度一定要相等，否则会报错
        const list = this.tableData;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          // multiHeader,
          header,
          // merges,
          data,
          filename: "零售出库单",
        });
      });
    },
    // 拼接数据
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      if (this.openAccountFlag) {
        var reg = sessionStorage.getItem("createDate").split(" ")[0];
        var defaultDate = [`${reg}`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${reg}`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      } else {
        var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
        this.searcForm.startDate = `${year}-${month}-01`;
        this.searcForm.endDate = `${year}-${month}-${date}`;
      }
      this.pickerDate = defaultDate;
    },
    datatimestatus() {
      this.pickerOptions = datatimestatus();
    },
    reset() {
      this.searcForm = {
        ...this.searcForm,
        no: "",
        param: "",
        status: "",
        startDate: "",
        endDate: "",
      };
      this.pickerDate = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.F {
  display: flex;
}
.FSB {
  display: flex;
  justify-content: space-between;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  // position: absolute;
  // bottom: 0px;
}
</style>
